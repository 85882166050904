.policy-body {
    position: absolute;
    text-align: justify;
    width: 500px;
    overflow: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.contact {
    margin-top: 20px;
}

.signature {
    margin-top: 40px;
}