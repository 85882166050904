.fullWidth {
    width: 100%;
}

.white {
    background-color: white;
}

.rs-picker-toggle-value {
    color: #14377d !important;
} 

.rs-picker-toggle:hover {
    border-color: #14377d !important;
}

.rs-picker-toggle-active {
    box-shadow: none !important;
    border-color: #14377d !important;
}

.rs-btn.rs-btn-active {
    background-color: #14377d !important;
    color: white !important;
}

.confirmation-response-message {
    text-align: center;
    font-size: 16px;
    color: red;
    font-weight: bold;
    padding-top: 15px;
}