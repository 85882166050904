/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

html,
body,
#root {
    height: 100%;
    background-color: #f5f5f5;
}

#root > div {
    height: calc(100% - 76px);
    background-color: #f5f5f5; 
}
