.run-results-header {
    margin-top: -20px;
    margin-bottom: 30px;
    font-size: 18px;
}

.header-label {
    display: inline-block;
    font-weight: bold;
    margin-right: 22px;
}

.header-data {
    display: inline-block;
    margin-left: 20px;
}

.run-results-grid {
    border-left: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
}

.rs-table-cell-header * {
    font-weight: bold;
}

.rs-table-row-header {
    border-bottom: 2px solid black;
}

.weekly-results-table {
    margin-bottom: 30px;
}

.daily-results-table {
    margin-bottom: 30px;
}

.accordion-body-mine {
    position: relative;
}

.pagination-strip {
    display: flex;
    padding-left: 0;
    list-style: none;
    justify-content: center;
    position: relative;
}

.pagination-button {
    width: auto;
    height: auto;
    margin: 5px;
    padding: 5px 10px;
    border-radius: 3px;
    user-select: none;
}

.pagination-button:hover {
    background-color: #deebf7;
    color: #14377d;
    border-color: #deebf7;
}

.pagination-button.active,
.pagination-button.active:hover {
    border: 1px solid #14377d;
    /*THEME*/
    color: #14377d
        /*THEME*/
}

.pagination-separator {
    font-weight: bold;
    font-size: 16px;
    height: auto;
    margin: 5px 10px;
}

.rs-nav-item {
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    color: #14377d !important;
    /*THEME*/
}

a.rs-nav-item.rs-nav-item-active {
    font-size: 14px;
    font-weight: bold;
    color: #14377d;
    /*THEME*/
    line-height: 14px;
}

.visibility-controls {
    margin-bottom: 10px;
}

.visibility-controls .fields-visibility-toggle {
    border: 1px solid #14377d;
    /*THEME*/
    padding: 8px 12px;
}

.visibility-controls .fields-visibility-toggle:nth-child(2) {
    border-left: 0;
    border-right: 0;
}

.visibility-controls .fields-visibility-toggle>.eye-icon {
    margin-right: 5px;
    margin-top: -2px;
}

.visibility-controls .fields-visibility-toggle>.eye-icon {
    fill: black;
}

.visibility-controls .fields-visibility-toggle>.text-label {
    color: black;
}

.visibility-controls .fields-visibility-toggle:hover {
    background-color: lightslategray;
}

.visibility-controls .fields-visibility-toggle:hover>.eye-icon {
    fill: white;
}

.visibility-controls .fields-visibility-toggle:hover>.text-label {
    color: white;
}

.visibility-controls .fields-visibility-toggle.rs-btn-active {
    background-color: #14377d;
    /*THEME*/
    color: white;
}

.visibility-controls .fields-visibility-toggle.rs-btn-active>.eye-icon {
    fill: white;
}

.visibility-controls .fields-visibility-toggle.rs-btn-active>.text-label {
    color: white;
}

.labels-cell span {
    display: block;
    text-align: right;
}

.labels-cell p {
    height: 31px;
    margin: 0 0 5px 0;
    line-height: 31px;
    text-align: right;
}

button.in-cell-button {
    border: 1px solid white;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    place-content: flex-end;
    border: 1px solid #14377d;
    /*THEME*/
}

button.in-cell-button>span {
    color: #14377d;
    /*THEME*/
}

button.in-cell-button>svg.rs-icon {
    fill: #14377d;
    /*THEME*/
}

button.in-cell-button:hover {
    background-color: #14377d;
    /*THEME*/
    transition: none;
}

button.in-cell-button:hover>span {
    color: white;
}

button.in-cell-button:hover>svg.rs-icon {
    fill: white;
}

button.in-cell-button>svg.rs-icon {
    margin-top: 3px;
}

button.in-cell-button>span {
    margin-right: 10px;
}

p.button-empty-label {
    margin-bottom: 10px;
}

.table-input {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    text-align: right;
}

.table-input.inline-input {
    display: inline-block;
    position: absolute;
    right: 0;
}

.custom-date-picker {
    width: 100%;
}

.custom-date-picker div[role="combobox"] {
    border-radius: 0.2rem;
}

.samplefield-box {
    position: relative;
    margin-bottom: 10px;
    height: 26px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.edit-mode .run-data-section {
    margin-bottom: 10px;
}

.insert-mode {
    height: 115px !important;
}

.rs-table-row-expanded {
    /* this class is used to handle expandable rows in the table. we use this as a trick to update row heights when edit mode is lauched.
the content is inserted, but we make it invisible to display the actual row real estate underneath. we just use this mechanism for the
height update */
    display: none;
}


/* customize calendar picker */
.rs-calendar {
    background-color: #deebf7;
    color: #14377d;
}

.rs-picker-toolbar {
    background-color: #deebf7;
}

.rs-picker-toggle-value {
    color: #14377d;
}

.rs-picker-toggle:hover {
    border-color: #14377d !important;
}

.rs-picker-toggle-active {
    box-shadow: none !important;
    border-color: #14377d !important;
}

button.rs-calendar-header-title {
    color: #14377d;
}

button.rs-calendar-header-forward>svg>path,
button.rs-calendar-header-backward>svg>path {
    color: #14377d;
}

button.rs-calendar-header-forward:hover>svg>path,
button.rs-calendar-header-backward:hover>svg>path {
    color: #8e8e93;
}

.rs-calendar-view>div[role='grid']>div[role='row']>div.rs-calendar-table-cell:not(.rs-calendar-table-cell-un-same-month) span {
    color: #14377d;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #14377d !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content .rs-calendar-table-cell-day {
    color: white !important;
}

.rs-calendar-table-cell-un-same-month span {
    color: #8e8e93;
}

.rs-calendar-table-cell-un-same-month:hover span {
    color: #14377d;
}

.rs-picker-toolbar-ranges>button {
    color: #14377d;
}

.rs-picker-toolbar-ranges>button:hover {
    color: #14377d;
    text-decoration: none;
    background-color: rgba(20, 55, 125, 0.15);
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgba(20, 55, 125, 0.15) !important;
    color: #14377d !important;
}

.rs-picker-toolbar-right button.rs-btn-primary {
    background-color: #14377d;
    /*THEME*/
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

select.batchID-select {
    text-align-last: right;
}

select.batchID-select option {
    direction: rtl;
}