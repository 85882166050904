.device-card {
    font-weight: bold;
    margin-bottom: 20px;
}

.device-card .card-body {
    padding-left: 0;
    padding-right: 0;
}

.device-card p {
    margin: 0;
}

.cards-wrapper {
    margin-bottom: 50px;
}

.form-row {
    margin-bottom: 30px;
}

.form-wrapper form {
    width: 100%;
}

.form-title {
    margin-bottom: 40px;
}

.info-note,
.form-footer {
    text-align: center;
}

.form-error {
    color: red;
}

.robot-name-label {
    font-size: 18px;
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 10px;
    /* color: #14377d; */
    font-weight: bold;
}
