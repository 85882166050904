.login-wrapper {
    position: absolute;
    height: auto !important;
    left: 50%;
    top: 450px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 20px;
    border-radius: 5px;
}

.logo {
    height: 50px;
    width: auto;
    margin-left: 250px;
}

.banner {
    height: auto;
    width: 100%;
}

.form-title-wrapper * {
    text-align: center;
    font-weight: 400 !important;
}

.form-title-wrapper h1 {
    color: #dd052b;
}

.form-title-wrapper h3 {
    color: #666666;
}

.form-footer * {
    text-align: center;
    margin-top: 20px;
}

.login-wrapper .form-row {
    margin-bottom: 5px;
}

.two-factor-select {
    width: 100%;
}

.login-wrapper label {
    margin-bottom: 10px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.content {
    margin-top: 40px;
    margin-bottom: 40px;
}

.field {
    margin-top: 20px;
}

.info-message {
    max-width: 330px;
    margin-bottom: 20px;
}