@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1320px;
    }
}

* {
    font-family: 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #666
}

h1, h2, h3, h4, h5 {
    font-family: "Open Sans";
    color: #dd3333;
    line-height: 1.5em;
    user-select: none;
}

.customer-wrapper {
    margin-bottom: 30px;
    font-size: 18px;
}

.customer-label {
    font-weight: bold;
    display: inline-block;
    margin-right: 15px;
}

.card-header {
    background-color: #deebf7 ;
    font-family: "Arial";
    font-weight: bold;
    line-height: 1.5em;
    padding: 12px 20px;
}

.list-group-item {
    padding: 12px 20px;
}

.list-group-item > a {
    text-decoration: none;
    color: #14377d; /*THEME*/
}

.list-group-item > a:hover {
    text-decoration: underline;
    color: #14377d; /*THEME*/
}

.card-body {
    font-family: "Arial";
    color: #666;
}

a {
    font-family: "Arial";
}

.btn-primary {
    background-color: #14377d !important; /*THEME*/
    color: white;
}

