.accordion-button:not(.collapsed) {
    background-color: #deebf7;
    color: #14377d; /*THEME*/
}
.accordion-button:focus { 
    box-shadow: none;
}

.accordion-body a,
.accordion-body a > li.list-group-item,
.accordion-button {
    color: #14377d; /*THEME*/
    text-decoration-color: #14377d !important; /*THEME*/
}

.product-item-card {
    height: 82px;
}

.product-item-card a {
    color: #14377d; /*THEME*/
    font-size: 16px;
    font-weight: 700;
    line-height: 58px;
}
.product-item-card a:hover {
    text-decoration: none;
}
