.breadcrumbs-container > ol {
    border-radius: 4px;
    padding: 12px 0px;
}

.breadcrumbs-container {
    display: block;
}

.breadcrumb-item > a {
    text-decoration: none;
    color: #14377d; /*THEME*/
}

.breadcrumb-item > a:hover {
    text-decoration: underline;
    color: #14377d; /*THEME*/
}