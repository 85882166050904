html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

#mainMenu {
    background-color: #deebf7;
    font-family: "Arial";
    margin-bottom: 20px;
    border: 0;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: #14377d;
    line-height: 20px;
    user-select: none;
}

a.navbar-brand:hover {
    cursor: pointer;
   text-decoration: none;
   color: #14377d;
}

#optionsToggle,
#optionsToggle:focus,
#optionsToggle:hover {
    color: #14377d; /*THEME*/
    text-decoration: none;
    user-select: none;
}

.rs-dropdown-menu {
    min-width: 150px;
}

.header-logo {
    height: 40px;
    width: auto;
    float: left;
    margin: 8px 0px 8px 20px;
}
